import React, { useContext, useEffect, useReducer, useState } from "react";
import styles from "./Step.module.css";
import StepsHeader from "../../../Components/StepsHeader/StepsHeader";
import PropertyAddress from "../../../assets/componentAssets/properttyAddress.png";
import info from "../../../assets/componentAssets/info.svg";
import defaultImage from "../../../assets/componentAssets/defaultImage.svg";
import { InputAdornment, TextField } from "@mui/material";
import { reducer } from "../consumptionReducer";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import { formStep6 } from "../../../Utility/utils";
import { form6State } from "../InitialState/form6State";
import BuildingData from "../../../Components/BuildingData/BuildingData";
import UpdatedFile from "../../../Components/Greenox/UpdatedFile/UpdatedFile";
import NavButton from "./NavButton";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { PatchApi, getApi } from "../../../Utility/network";
import UploadImage from "../../../Components/UploadImage/UploadImage";
import axios from "axios";
function Step6() {
  const { state: contextState, setState } = useContext(AppContext);
  const [data, setData] = useState({ name: "sample", size: "20kb" });
  const [addError, setAddError] = useState({});
  const [image, setImage] = useState();
  const [buildingImg, setBuildingImg] = useState([]);
  const style = { width: "100%" };
  const [add, setAdd] = useState({ street: "", postcode: null, location: "" });
  const [state, dispatch] = useReducer(reducer, form6State);
  const handleChange = (name, value) => {
    setAdd((prev) => ({ ...prev, [name]: value }));

    setAddError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const { info } = contextState.otherData;
    if (info) {
      setAdd(info);
    }
  }, [contextState.otherData]);
  useEffect(() => {
    getData();
  }, []);
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!add.street) {
      formIsValid = false;
      errors["street"] = "Street is required";
    }
    const pincodeRegex = /^\d{3,5}$/;
    if (!add.postcode) {
      formIsValid = false;
      errors["postcode"] = "Postcode is required";
    } else if (!pincodeRegex.test(add.postcode)) {
      formIsValid = false;
      errors["postcode"] = "Postcode must be between 3 to 5 digits";
    }
    if (!add.location) {
      formIsValid = false;
      errors["location"] = "Location is required";
    }

    setAddError(errors);
    return formIsValid;
  };

  const handleSubmit = async (num, type) => {
    try {
      if (type === "next") {
        if (!handleValidation()) {
          return;
        }

        try {
          const postData = await PatchApi(
            `/energy_consumption/form/${contextState.form_id}/`,
            { address: [add] }
          );
          if (postData.status === 200 || postData.status === 201) {
            setState((prev) => ({
              ...prev,
              filledPage:
                prev.filledPage >= prev.page + 1
                  ? prev.filledPage
                  : prev.page + 1,
              page: prev.page + 1,
              otherData: {
                ...prev.otherData,
                info: add,
              },
            }));
            // setState((prev) => ({ ...prev, page: prev.page + 1 }));
           
          } else {
            throw Error("api failed");
          }
        } catch (error) {
          alert(error);
        }
      } else if (type === "prev") {
        setState((prev) => ({ ...prev, page: prev.page - 1 }));
      } else if (type === "save") {
        await getApi(`/energy_consumption/form/${contextState.form_id}/generate_link`);
        // navigate("/login");
      }
    } catch (error) {
      alert("something went wrong");
    }
  };

  useEffect(() => {
    if (image) {
      uploadImageApi();
    }
  }, [image]);

  const uploadImageApi = () => {
    const formData = new FormData();
    formData.append("image", image);
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .patch(
        `http://43.204.177.46/energy_consumption/form/${contextState.form_id}/upload_building_photos/`,
        formData,
        axiosConfig
      )
      .then((response) => {
        setImage();
        getData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getData = async () => {
    try {
      const response = await getApi(
        `/energy_consumption/form/${contextState.form_id}/`
      );
      setBuildingImg(response.data.data.building_images);
    } catch (error) {}
  };

  return (
    <div>
      <div className={styles.con}>
        <StepsHeader
          image={PropertyAddress}
          heading={"Property Address"}
          secPara="Please provide proper information"
        />
        <div className={styles.fieldDetails}>
          <div className={styles.fieldCom}>
            <div className={styles.fields}>
              <CustomInput
                key={1}
                label="Street or House Number"
                name="street"
                value={add.street}
                onChange={handleChange}
                options={[]}
                error={!!addError.street}
                helperText={addError.street}
                popData={address?.number}
              />
            </div>
            <div className={styles.fields}>
              <CustomInput
                key={1}
                label="Postcode"
                name="postcode"
                value={add.postcode}
                onChange={handleChange}
                options={[]}
                error={!!addError.postcode}
                helperText={addError.postcode}
                popData={address?.Postcode}
                type="number"
              />
            </div>
            <div className={styles.fields}>
              <CustomInput
                key={1}
                label="Location"
                name="location"
                value={add.location}
                onChange={handleChange}
                options={[]}
                error={!!addError.location}
                helperText={addError.location}
                popData={address?.Location}
              />
            </div>
          </div>
          <div>
            <p>
              <b>Upload building Photo </b>
            </p>
            <UploadImage getImage={setImage} imgs={image} />
            <div style={{ marginTop: "20px" }}>
              {buildingImg.map((item) => {
                const filename = item.image.split("/").pop();
                return (
                  <div
                    style={{ background: "#AFDB2826" }}
                    className={styles.imageContainer}
                  >
                    <div>
                      <img
                        src={item.image}
                        style={{ marginRight: "15px", width: "80px" }}
                      />
                    </div>
                    <div>{filename}</div>
                    <div></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <NavButton handleSubmit={handleSubmit} />
    </div>
  );
}

export default Step6;
const address = {
  number: [
    {
      heading: "Street Or House Number",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
  Postcode: [
    {
      heading: "Postcode",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
  Location: [
    {
      heading: "Location",
      para1:
        " Please enter the address of the building for which the energy certificate is to be created.",
      para2:
        " You can enter the billing and delivery address in the next step.",
    },
  ],
};
