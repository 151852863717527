export const germanText = {
  immediately_available: "Sofort verfügbar",
  legally_valid: "Rechtsgültig für 10 Jahre",
  energy_certificate: "Energieausweis nach GEG",
  consumption_certificate: "Verbrauchsbescheinigung",
  need_certificate: "Brauchen Zertifikat",
  save_resume_later: "Speichern und später fortsetzen",
  next: `Weiter`,
  previous: "Vorherige",
  google_review: `Google-Bewertung`,
  our_payment_methods: `Unsere Zahlungsarten`,
  energy_certificates: `Energieausweise`,
  create_consumption_certificate: `einen Verbrauchsausweis erstellen`,
  create_needs_certificate: `ein Bedarfszertifikat erstellen`,
};

export const consumCertGerText = {
  step_1: "Stufe 1",
  step_2: "Stufe 2",
  step_3: "Stufe 3",
  step_4: "Stufe 4",
  step_5: "Stufe 5",
  step_6: "Stufe 6",
  step_7: "Stufe 7",
  step_8: "Stufe 8",
  building_info: "Gebäude-Infos",
  heating_info: "Infos zur Heizung",
  heat_water_info: "Wärme Wasser Info",
  vacancy_info: "Infos zum Stellenangebot",
  property_info: "Immobilien-Infos",
  property_address: "Adresse der Immobilie",
  certificate: "Zertifikat",
  billing_delivery_address: "Rechnungs- und Lieferadresse",
  building_data: "Gebäudedaten",
  provided_information: "Bereitgestellte Informationen",
  close: "Schließen Sie",
  occasion: "Anlass",
  occasion_error: `Anlass ist erforderlich`,
  occasion_paragraph: `Wenn Sie den Energieausweis für eine Finanzierung oder einen Kreditantrag benötigen, wählen Sie bitte als Ausstellungsgrund „Sonstiges“. Energieausweise mit den Ausstellungsgründen „Neubau“ oder „Modernisierung“ können nicht nach § 80 Satz 1 und 2 GEG bestellt werden.`,
  postal_code: "Postleitzahl",
  postal_code_error: `Ungültige Postleitzahl`,
  building_type: `Gebäude Typ`,
  building_type_error: `Gebäudetyp ist erforderlich`,
  building_type_paragraph: `Für gemischt genutzte Gebäude empfehlen wir einen Bedarfsausweis, da dieser genauere Ergebnisse liefert.`,
  year_of_construction: "Jahr der Errichtung",
  year_of_construction_error: `Das Jahr muss zwischen 1850 und 2024 liegen.`,
  number_of_apartments: "Anzahl der Wohnungen",
  number_of_apartments_error:
    "Die Anzahl der Wohnungen ist als Zahl anzugeben.",
  number_of_apartments_paragraph: `Da Energieausweise nur für das gesamte Gebäude und nicht für einzelne Wohnungen ausgestellt werden können, sind Angaben zu allen Wohnungen des Gebäudes erforderlich. Bitte zählen Sie Einliegerwohnungen als separate Wohneinheiten. Bei Einfamilienhäusern, wenn keine weiteren Wohnungen im Gebäude vorhanden sind, wählen Sie bitte 1 Wohneinheit.`,
  eMail: `E-Mail`,
  eMail_error: `Ungültige E-Mail Adresse`,
  eMail_para_1:
    "Bitte geben Sie eine gültige E-Mail-Adresse an, damit wir Sie kontaktieren können, wenn wir Fragen zu Ihren Angaben haben.",
  eMail_para_2:
    "Sie erhalten auch die Auftragsbestätigung und die Rechnung über die von Ihnen angegebene E-Mail-Adresse.",
  number_of_floors: "Anzahl der Etagen",
  number_of_floors_error: "Die Anzahl der Stockwerke ist als Zahl anzugeben.",
  number_of_floors_paragraph: ``,
  floor_to_ceiling_height: "Höhe vom Boden bis zur Decke (in Metern)",
  floor_to_ceiling_height_error:
    "Die Höhe vom Boden bis zur Decke (in Metern) wird als Zahl benötigt.",
  floor_to_ceiling_height_paragraph: ``,
  is_there_heated_basement: `Gibt es ein beheiztes Untergeschoss`,
  yes: "Ja",
  no: "NEIN",
  Please_provide_proper_information:
    "Bitte stellen Sie korrekte Informationen zur Verfügung",
  heating_system_are_installed: `Wie viele Heizungsanlagen werden installiert?`,
  energy_sources: ` Energiequellen `,
  energy_sources_error: `Eine Energiequelle ist erforderlich.`,
  energy_sources_paragraph: `Bitte wählen Sie hier Ihre Hauptenergiequelle`,
  unit: `Einheit`,
  unit_error: `Unit ID ist erforderlich.`,
  unit_para_1: `Bitte geben Sie die korrekte Verbrauchseinheit des Energieträgers/Brennstoffs ein.`,
  unit_para_2: `Diese können Sie in der Regel auf Ihrer Heizkostenabrechnung finden.`,
  name_of_provider_line: `Wenn Sie den Primärenergiefaktor Ihres Energieversorgers nicht kennen, teilen Sie uns bitte den Namen Ihres Versorgers mit. Sie können hier Notizen hinterlassen.`,
  date: `Datum`,
  date_paragraph: `"Um einen Verbrauchsausweis zu beantragen, müssen die Verbrauchswerte der letzten drei Jahre vorliegen, d.h. der erste Abrechnungszeitraum muss mindestens drei Jahre zurückliegen. Tragen Sie hier den Monat und das Jahr des Beginns des ersten Abrechnungszeitraums ein."`,
  consumption_from_this: "Verbrauch für November 2021 bis Oktober 2022",
  consumption_from_this_error: `Das Feld „Verbrauch“ ist ungültig. Es muss eine nicht-negative Zahl sein und darf kein „e“, „-“ oder „+“ enthalten.`,
  consumption_from_this_para_1:
    "Bitte geben Sie hier den Gesamtenergieverbrauch für jeden Zeitraum an. Ein Abrechnungszeitraum ist immer ein Jahr lang.",
  consumption_from_this_para_2:
    "Bei Mehrfamilienhäusern sollten Sie den Gesamtenergieverbrauch des gesamten Gebäudes angeben, da sich Energieausweise immer auf das gesamte Gebäude beziehen.",
  consumption_from_this_para_3:
    "Wenn es sich um eine Kraft-Wärme-Kopplungsanlage (KWK) handelt, geben Sie bitte nur die erzeugte Wärmemenge (ohne Strom) an.",
  included_heating_system: `Ist Warmwasser im Heizungssystem inbegriffen (1)`,
  included_heating_system_paragraph: `Bitte geben Sie hier an, ob der Warmwasser-Energieverbrauch bereits in dem oben angegebenen Verbrauch enthalten ist oder nicht. Sie können den Warmwasser-Energieverbrauch auch separat angeben.`,
  consumption_is_unknown: `Der Verbrauch ist unbekannt`,
  percentage_share_of_hot_water: `Prozentualer Anteil von Warmwasser`,
  percentage_share_of_hot_water_paragraph: `Geben Sie hier den Anteil der Warmwasserversorgung an. Der Standardwert ist 18%.`,
  vacancy_info_paragraph: `Wenn das Objekt nicht während des gesamten Zeitraums voll belegt war, können Sie hier die Leerstandsquote in Prozent angeben.`,
  vacancy_info_para_1: `Bitte geben Sie die Leerstandsquote in % für ein ganzes Jahr und für das gesamte Gebiet an.`,
  vacancy_info_para_2: `Berechnung der Leerstandsquote Sie addieren die Leerstandsquote über die Monate und teilen diese durch 12. Dann multiplizieren Sie den Wert mit 100 und Sie erhalten den Prozentsatz.`,
  vacancy_info_para_3: `Beispiel: Wenn die Immobilie innerhalb von 12 Monaten 3 Monate lang leer steht, wären dies 25 %. Berechnung: 3 / 12 * 100 = 25%`,
  heating_system_installed_on: `Jahr, in dem das 1. Heizsystem installiert wurde`,
  heating_system_installed_on_error: `Das Jahr muss zwischen 1903 und 2024 liegen.`,
  heating_system_installed_on_paragraph: `Das Baujahr der Heizungsanlage finden Sie im Abgasmessprotokoll, in der Betriebsanleitung, auf dem Typenschild, im Abnahmeprotokoll usw. Wenn Sie Fernwärme nutzen, geben Sie bitte das Baujahr der Fernwärmeübergabestation an. Wenn Ihr Kessel modernisiert wurde, geben Sie bitte das Jahr der Modernisierung an. Das Jahr, in dem der Brenner modernisiert wurde, ist nicht relevant. Wenn Sie mehrere Geräte haben, geben Sie bitte das überwiegende Baujahr an.`,
  type_of_ventilation: `Art der Belüftung`,
  type_of_ventilation_paragraph: `Wie wird das Gebäude belüftet? Beispiele für Lüftungsanlagen ohne Wärmerückgewinnung sind Abluftanlagen in fensterlosen Räumen wie Badezimmern. Lüftungsanlagen mit Wärmerückgewinnung sind zum Beispiel zentrale oder dezentrale Zu- und Abluftanlagen, die mit Wärmetauschern ausgestattet sind.`,
  exhaust_air_system: `Abluftsystem`,
  without_heat_recovery: `Lüftungsanlage ohne Wärmerückgewinnung`,
  with_heat_recovery: `Lüftungsanlage mit Wärmerückgewinnung`,
  shaft_ventilation: `Schachtbelüftung`,
  windows_ventilation: `Fensterlüftung`,
  living_space: `Wohnfläche (nicht Gebäudefläche)`,
  living_space_error: `Bitte geben Sie eine gültige Wohnfläche (positive Zahl) ein.`,
  living_space_paragraph: `Der Energieausweis ist immer auf das jeweilige Gebäude bezogen. Wir bitten Sie daher, die Wohnfläche des gesamten Gebäudes (ohne Keller) in Quadratmetern anzugeben. Anhand dieser Angaben wird die Nutzfläche des Gebäudes im Energieausweis automatisch berechnet.`,
  insulation_regulation_met: `Wurde die Wärmeschutzverordnung von 1977 eingehalten?`,
  insulation_regulation_met_paragraph: ``,
  building_part: `Gebäudeteil`,
  building_part_error: `Bitte wählen Sie ein Gebäudeteil aus.`,
  building_part_para_1: `Im lokalen Sinne gilt ein Gebäude nur dann als gemischtes Gebäude, wenn zwei getrennte Energieausweise ausgestellt werden müssen. Dies ist der Fall, wenn die Gewerbe- und Wohnflächen in einem engeren Verhältnis als 90% und 10% zueinander stehen. In allen anderen Fällen muss „Wohngebäude“ gewählt werden.`,
  building_part_para_2: `Außerdem kann ein gemeinsamer Energieausweis ausgestellt werden, wenn sich die Heizungsanlage nicht wesentlich unterscheidet.`,
  building_part_para_3: `Wir stellen derzeit keine Energieausweise für Nichtwohngebäude aus.`,
  property_have_basement: `Verfügt die Immobilie über ein Untergeschoss?`,
  lies_below_ground_floor: `Was verbirgt sich unter dem Erdgeschoss?`,
  unheated_basement: `Unbeheizter Keller`,
  heated_cellar: `Beheizter Keller`,
  no_floor_slab: `Kein Keller vorhanden/ Bodenplatte`,
  renewables_energy_used: `Wird erneuerbare Energie genutzt?`,
  do_you_use: `Was verwenden Sie?`,
  solar_theramal_energy: `Solar-Theramal-Energie`,
  photovoltaics: `Fotovoltaik`,
  heat_pump: `Wärmepumpe`,
  biomass: `Biomasse`,
  renewables_energy_used_for: `Wozu werden erneuerbare Energien verwendet?`,
  heating: `Heizung`,
  hotwater: `Warmwasser`,
  electricity: `Elektrizität`,
  optional_information_about_equipment: `Möchten Sie optionale Informationen über das Gerät zur Verfügung stellen?`,
  sun_protection: `Welchen Sonnenschutz gibt es?`,
  sun_protection_error: `Bitte wählen Sie eine Sonnenschutzoption.`,
  glare_protection: `Kein Sonnen-/Blendschutz`,
  flare_protection: `Sonnen- und/oder Blendschutz (Rollläden, Jalousien), automatisch betrieben`,
  only_glare_protection: `Nur Blendschutz (meist im Bürogebäude)`,
  light_directing: `Nur Blendschutz, lichtlenkend (meist in Bürogebäuden)`,
  insulation_glazing: `Verfügt die Immobilie über eine Wärmedämmung/Isolierverglasung?`,
  attic_insulation: `Ist das Dach oder der Dachboden isoliert?`,
  house_number: `Straße oder Hausnummer`,
  house_number_error: `Straße ist erforderlich`,
  house_number_para_1: `Bitte geben Sie die Adresse des Gebäudes an, für das der Energieausweis erstellt werden soll.`,
  house_number_para_2: `Im nächsten Schritt können Sie die Rechnungs- und Lieferadresse eingeben.`,
  postcode: `Postleitzahl`,
  postcode_error: `Postleitzahl ist erforderlich`,
  postcode_para_1: `Bitte geben Sie die Adresse des Gebäudes an, für das der Energieausweis erstellt werden soll.`,
  postcode_para_2: `Im nächsten Schritt können Sie die Rechnungs- und Lieferadresse eingeben.`,
  location: `Standort`,
  location_error: `Standort ist erforderlich`,
  location_para_1: `Bitte geben Sie die Adresse des Gebäudes an, für das der Energieausweis erstellt werden soll.`,
  location_para_2: `Im nächsten Schritt können Sie die Rechnungs- und Lieferadresse eingeben.`,
  upload_building_photo: `Gebäudefoto hochladen`,
  drag_drop_upload: `Ziehen und Ablegen oder Hochladen`,
  energy_certificate_available: `Ihr Energieausweis ist jetzt verfügbar`,
  energy_certificate_available_para_1: `Hier ist Ihr vereinfachtes Musterexemplar mit Ihren Daten. Bitte beachten Sie, dass es sich hierbei nicht um das Original des Personalausweises handelt. Sie erhalten das Original, nachdem wir Ihre Bestellung geprüft haben.`,
  energy_certificate_available_para_2: `Bitte überprüfen Sie noch einmal alle Daten und klicken Sie dann auf „ Weiter“.`,
  i_reviewed_entries: `Ich habe meine Eingaben und Energiewerte überprüft und keine Fehler gefunden.`,
  order_type: `Auftragsart`,
  order_type_paragaraph: `Bitte wählen Sie aus, ob Sie den Antrag als Privatperson oder als Unternehmen einreichen. Wenn Sie als Gewerbetreibender mit einer Umsatzsteuer-Identifikationsnummer bestellen, wählen Sie „Gewerblich“. Wenn Sie als Unternehmer ohne Umsatzsteuer-Identifikationsnummer bestellen, wählen Sie entweder „Privat“ oder „Gewerblich ohne Umsatzsteuer-Identifikationsnummer“.`,
  private_owners: `Privateigentümer`,
  commercial: `Kommerziell`,
  commercial_ustId: `Kommerziell (ohne USt.ID)`,
  phone_number: `Rufnummer`,
  first_name: `Vorname`,
  surname: `Nachname`,
  billing_house_number: `Straße & Hausnummer`,
  billing_house_number_para_1: `Bitte geben Sie die Adresse an, die auf der Rechnung erscheinen soll. Wenn Sie die optionale Postzustellung wählen, senden wir Ihren Energieausweis auch an diese Adresse.`,
  billing_house_number_para_2: `Sollten Sie abweichende Adressen für den Versand und die Rechnung benötigen, geben Sie bitte die zusätzliche Adresse im Kommentarfeld an oder wenden Sie sich direkt an unseren Kundenservice.`,
  billing_house_number_para_3: `Wenn Ihre Adresse im Ausland liegt, können Sie auch eine ausländische Adresse angeben. Wir liefern auch ins Ausland.`,
  company_name: `Name des Unternehmens`,
  VAT_ID: `UMSATZSTEUER-ID`,
  common_error: `dieses Feld darf nicht leer sein`,
  add_on_services: `Zusätzlicher Service`,
  postal_dispatch_invoices: `Hochwertiger Postversand von ID-Karten und Rechnungen`,
  express_exhibition_today: `Express-Ausstellung heute`,
  advanced_verification: `Erweiterte Verifizierung (empfohlen)`,
  free_correction_option: `Kostenlose Korrekturmöglichkeit, wenn falsche Informationen entdeckt werden`,
  experts_explain: `Wir beantworten Ihre Fragen - Experten erklären!`,
  delivery_method: `Liefermethode der ID-Karte (Liefermethode der ID-Karte)`,
  delivery_method_2: `Versandart des Ausweises (gedruckte Kopie per Post und digital per E-Mail)`,
  order_summary: `Zusammenfassung der Bestellung`,
  energy_performance_certificate: `Ausweis über die Gesamtenergieeffizienz`,
  certificate_residential_build: `Verbrauchsausweis für Wohngebäude`,
  i_agree_text: `Ich bin mit den Allgemeinen Geschäftsbedingungen einverstanden und habe die Bestimmungen zum Datenschutz und zur Widerrufsbelehrung zur Kenntnis genommen`,
  terms_conditions: `Bedingungen und Konditionen`,
  total: `Insgesamt`,
  order_now: `Jetzt bestellen`,
};

export const needCertificateGerText = {
  step_1: "Stufe 1",
  step_2: "Stufe 2",
  step_3: "Stufe 3",
  step_4: "Stufe 4",
  step_5: "Stufe 5",
  step_6: "Stufe 6",
  step_7: "Stufe 7",
  step_8: "Stufe 8",
  building_info: "Gebäude-Infos",
  structure_details: `Struktur Details`,
  building_component: `Gebäudekomponente`,
  heat_generator_details: `Details zum Wärmeerzeuger`,
  property_address: "Adresse der Immobilie",
  file_upload: `Datei-Upload`,
  certificate: "Zertifikat",
  billing_delivery_address: "Rechnungs- und Lieferadresse",
  building_data: "Gebäudedaten",
  provided_information: "Bereitgestellte Informationen",
  occasion: "Anlass",
  occasion_error: `Anlass ist erforderlich`,
  occasion_paragraph: `Wenn Sie den Energieausweis für eine Finanzierung oder einen Kreditantrag benötigen, wählen Sie bitte als Ausstellungsgrund „Sonstiges“. Energieausweise mit den Ausstellungsgründen „Neubau“ oder „Modernisierung“ können nicht nach § 80 Satz 1 und 2 GEG bestellt werden.`,
  postal_code: "Postleitzahl",
  postal_code_error: `Ungültige Postleitzahl`,
  building_type: `Gebäude Typ`,
  building_type_error: `Gebäudetyp ist erforderlich`,
  building_type_paragraph: `Für gemischt genutzte Gebäude empfehlen wir einen Bedarfsausweis, da dieser genauere Ergebnisse liefert.`,
  number_of_apartments: "Anzahl der Wohnungen",
  number_of_apartments_error:
    "Die Anzahl der Wohnungen ist als Zahl anzugeben.",
  number_of_apartments_paragraph: `Da Energieausweise nur für das gesamte Gebäude und nicht für einzelne Wohnungen ausgestellt werden können, sind Angaben zu allen Wohnungen des Gebäudes erforderlich. Bitte zählen Sie Einliegerwohnungen als separate Wohneinheiten. Bei Einfamilienhäusern, wenn keine weiteren Wohnungen im Gebäude vorhanden sind, wählen Sie bitte 1 Wohneinheit.`,
  year_of_construction: "Jahr der Errichtung",
  year_of_construction_error: `Das Jahr muss zwischen 1850 und 2024 liegen.`,
  no_of_floors: `Anzahl der Etagen`,
  eMail: `E-Mail`,
  eMail_error: `Ungültige E-Mail Adresse`,
  eMail_para_1:
    "Bitte geben Sie eine gültige E-Mail-Adresse an, damit wir Sie kontaktieren können, wenn wir Fragen zu Ihren Angaben haben.",
  eMail_para_2:
    "Sie erhalten auch die Auftragsbestätigung und die Rechnung über die von Ihnen angegebene E-Mail-Adresse.",
  number_of_floors: "Anzahl der Etagen",
  number_of_floors_error: "Die Anzahl der Stockwerke ist als Zahl anzugeben.",
  number_of_floors_paragraph: ``,
  heated_area_floor: `Prozentsatz der beheizten Fläche im 1. Stock`,
  provide_your_proper_information: `Geben Sie Ihre korrekten Informationen an.`,
  structure_details_para: `Um den Energiebedarf der Immobilie genau zu ermitteln, benötigen wir von Ihnen folgende Informationen`,
  structure_details_para_note: `Bitte geben Sie lediglich die Form und Außenmaße Ihres Gebäudeteils an.`,
  as_per_your_floor_plan: `Wie in Ihrem Grundriss`,
  wall_length: `Wand Länge A`,
  corners: `Ecken`,
  custom: `Benutzerdefiniert`,
  total_floor_height: `Durchschnittliche Gesamtbodenhöhe (einschl. Decke)`,
  living_space: `Lebensraum`,
  construction_type: `Bauart`,
  material_used: `Verwendetes Material`,
  part_the_building: `Teil des Gebäudes`,
  type_of_ventilation: `Art der Belüftung`,
  exhaust_air_system: `Abluftsystem`,
  without_heat_recovery: `Lüftungsanlage ohne Wärmerückgewinnung`,
  with_heat_recovery: `Lüftungsanlage mit Wärmerückgewinnung`,
  shaft_ventilation: `Schachtbelüftung`,
  windows_ventilation: `Fensterlüftung`,
  cooling_system: `Gibt es ein Kühlsystem?`,
  yes: "Ja",
  no: "NEIN",
  from_electricity: `Von Elektrizität`,
  delivered_cold: `Kalt ausgeliefert`,
  passive_cooling: `Passive Kühlung`,
  cooling_from_heat: `Kühlung durch Wärme`,
  has_leaks_been_conducted: `Wurde ein Test auf undichte Stellen durchgeführt (Blower Door Test)?`,
  compliance_with_tightness: `Übereinstimmung mit der Dichtheit`,
  without_leak_test: `Ohne Dichtheitsprüfung`,
  obvious_leaks: `Offensichtliche Lecks`,
  other: `Andere`,
  required_field_error: `Dieses Feld ist erforderlich`,
  roof: `Dach`,
  building_component_error: `Bitte geben Sie Details im Abschnitt Fenster, Keller, Isolierung ein`,
  roof_shape: `Dachform`,
  flat_roof: `Flachdach`,
  gable_roof: `Giebeldach`,
  hip_roof: `Walmdach`,
  pult_roof: `Pultdach`,
  walm_flat: `Walm + Wohnung`,
  structure: `Struktur`,
  attic: `Dachboden`,
  attic_type: `Dachboden Typ`,
  attic_height: `Dachgeschoss Höhe`,
  roof_pitch: `Dachneigung`,
  ridge_length: `Länge des Firstes`,
  ridge_width: `Breite des Firstes`,
  is_there_second_attic: `Gibt es ein zweites Dachgeschoss?`,
  is_there_second_attic_heated: `Gibt es einen zweiten beheizten Dachboden`,
  second_attic_height: `Zweite Dachgeschosshöhe`,
  knee_wall_or_sill: `Hat die Immobilie eine Kniewand oder Schwelle`,
  knee_wall_sill_height: `Kniewand / Schwellenhöhe`,
  window: `Fenster`,
  window_top_para_1: `Das angegebene Alter Ihrer Fenster, 47 Jahre, scheint ungewöhnlich hoch zu sein. Bitte überprüfen Sie diese Angaben. Wenn sie korrekt sind, können Sie fortfahren.`,
  have_dimensions_window: `Haben Sie die Abmessungen der einzelnen Fenster?`,
  windows_in_wall: `Bitte geben Sie alle Fenster in Wand A ein`,
  window_top_para_2: `Seit Mai 2021 schreibt das Gebäudeenergiegesetz (GEG) vor, dass detaillierte Bilder des Sanierungszustands, der Gebäudehülle und der Anlagentechnik (z.B. Wärmeerzeuger) eingereicht werden müssen. Auf dem Energieausweis wird jedoch nur das Bild des Gebäudes gezeigt.`,
  wall: `Wand`,
  window_type: `Fenster Typ`,
  window_area_wall: `Prozentualer Anteil der Fensterfläche am Wal`,
  type: `Typ`,
  length: `Länge`,
  width: `Breite`,
  count: `Zählen Sie`,
  basement: `Untergeschoss`,
  below_ground_floor: `Was verbirgt sich unter dem Erdgeschoss?`,
  floor_slab: `floor_slab`,
  insulation: `Isolierung`,
  property_insulated: `Ist die Immobilie isoliert?`,
  exterior_walls: `Äußere Wände`,
  roof_or_top_ceiling: `Dach oder Oberkante Decke`,
  floor_basement: `Stockwerk / Untergeschoss`,
  basement_walls: `Keller-Wände`,
};
