import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./HeaderNew.module.css";
import { Button } from "@mui/material";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import {
  formatDate,
  getPaymentStatus,
  getStatusById,
  getStatusByIds,
  steps,
} from "../../../Utility/utils";
import tick from "../../../assets/dashboard/tick.png";
import { PatchApi, getApi } from "../../../Utility/network";

function HeaderNew({ showDetails = false, ...props }) {
  let { id: _id } = useParams();
  const [item, setItem] = useState(null);
  const { state, setState } = useContext(AppContext);
  const [page, setPage] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    setPage(state.filledPage);
  }, [state]);
  const handleClick = (_type, _index) => {
    if (_type === "past" || _type === "active") {
      setState((prev) => ({ ...prev, page: _index }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = await getApi(
          `/energy_consumption/user_energy_consumption/${_id}`
        );
        const data = postData?.data;
        setItem(data);
        const {
          heating_info,
          building_type,
          construction_year,
          user,
          floors,
          floor_to_ceiling_height,
          postal_code,
          is_there_heated_basement,
          number_of_apartments,
          occasion,
          vacancy,
          living_space,
          building_part,
          ventilation,
          was_the_1977_thermal_insulation_regulation_met,
          does_property_have_basement,
          what_lies_below_the_ground_floor,
          are_renewable_energy_used,
          type_of_renewable_energy,
          energy_used_for,
          is_there_cooling_system,
          type_of_cooling_system_used,
          optional_information_abt_equipment,
          sun_protection,
          does_the_property_have_thermal_insulation,
          is_there_roof_or_attic_insulation,
          address,
          billing_address,
          id,
        } = postData.data;
        const formId = id;
        const new_heating_info = heating_info.map((item, index) => {
          return {
            ...item,
            energy_source_id: item.energy_source.id,
            unit_id: item.unit.id,
          };
        });
        const new_ventilation = ventilation.map((item, index) => {
          return item.id;
        });
        const new_type_of_renewable_energy = type_of_renewable_energy.map(
          (item, index) => {
            return item.id;
          }
        );
        const new_energy_used_for = energy_used_for.map((item, index) => {
          return item.id;
        });
        const new_type_of_cooling_system_used = type_of_cooling_system_used.map(
          (item, index) => {
            return item.id;
          }
        );

        let page = 1;
        if (new_heating_info && new_heating_info.length > 0) {
          page = 2;
        }
        if (new_ventilation && new_ventilation.length > 0) {
          page = 5;
        }
        if (address && address.length > 0) {
          page = 6;
        }
        if (billing_address) {
          page = 8;
        }
        if (!formId) {
          page = 1;
        }

        setState((prev) => ({
          ...prev,
          otherData: {
            ...prev.otherData,
            lastStep: billing_address,
            buildingData: {
              building_type_id: building_type?.id,
              construction_year,
              email: user?.email,
              floors,
              occasion_id: occasion?.id,
              postal_code,
              is_there_heated_basement,
              floor_to_ceiling_height,
              number_of_apartments,
              vacancy,
            },
            yearArray: new_heating_info,
            noOfHeatingSystem: heating_info.length,
            construction_year: construction_year,
            info: {
              living_space,
              building_part,
              ventilation_id: new_ventilation,
              was_the_1977_thermal_insulation_regulation_met,
              does_property_have_basement,
              what_lies_below_the_ground_floor,
              are_renewable_energy_used,
              type_of_renewable_energy_id: new_type_of_renewable_energy,
              energy_used_for_id: new_energy_used_for,
              is_there_cooling_system,
              type_of_cooling_system_used_id: new_type_of_cooling_system_used,
              optional_information_abt_equipment,
              sun_protection,
              does_the_property_have_thermal_insulation,
              is_there_roof_or_attic_insulation,
              ...(address && address.length > 0 && { ...address[0] }),
            },
          },
          form_id: formId,
          filledPage: page,
          page: page,
        }));
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };
    fetchData();
  }, [_id]);

  const handleIssue = async (type) => {
    // setState((prev) => ({ ...prev, page: prev.page + 1 }));
    const postEnergy = await getApi(
      `energy_consumption/form/${state.form_id}/calculate_energy_consumption/`
    );
    if (postEnergy.status === 200 || postEnergy.status === 201) {
      const generate_pdf = await getApi(
        `records/${postEnergy?.data?.data?.id}/generate_energy_certificate/`
      );
      if (generate_pdf.status === 200 || generate_pdf.status === 201) {
        setState((prev) => ({
          ...prev,
          pdfId: postEnergy?.data?.data?.id,
        }));
        if (type !== 2) {
          patchData(type, postEnergy);
        } else {
          navigate(
            `/consumption-certificate/${state.form_id}/${postEnergy?.data?.data?.id}`
          );
        }
      }
    }
  };

  const patchData = async (type, postEnergy) => {
    try {
      const postData = await PatchApi(
        `/energy_consumption/form/${state.form_id}/`,
        { certificate_status: 2 }
      );
      if (postData.status === 200 || postData.status === 201) {
        navigate(
          `/consumption-certificate/${state.form_id}/${postEnergy?.data?.data?.id}`
        );
      }
    } catch (error) {}
  };

  return (
    <div className={styles.header}>
      {showDetails && item && (
        <div className={styles.headDEtails}>
          <div className={styles.left}>
            <p className={styles.name}>
              {item?.user?.first_name} {item?.user?.last_name} |{" "}
              {item?.user?.email}
            </p>
            <p className={styles.submitted}>Submitted On</p>
            <p className={styles.dates}>{formatDate(item.created)}</p>
          </div>
          <div className={styles.right}>
            <div className={styles.certificate}>
              <p className={styles.sname}>Certificate</p>
              <div className={styles.status}>
                <span>{getStatusById(item?.certificate_status)}</span>
              </div>
            </div>
            <div className={styles.certificate}>
              <p className={styles.sname}>Payment Status</p>
              <p
                className={styles.status1}
                style={{
                  backgroundColor: `${
                    item?.payment_status == 2
                      ? "linear-gradient(90deg, #22a156 0%, #afdb28 100%)"
                      : "#ffffff"
                  }`,
                }}
              >
                {getPaymentStatus(item?.payment_status)}
              </p>
            </div>

            <Button
              variant="contained"
              size="large"
              onClick={() =>
                handleIssue(
                  item?.certificate_status?.id
                    ? item.certificate_status?.id
                    : null
                )
              }
              disabled={
                !(item?.payment_status == 2 &&
                item?.recommendations.length > 0 &&
                item?.serial_number.length > 0)
              }
              sx={{
                padding: "12px 25px",
                background: " linear-gradient(90deg, #22A156 0%, #AFDB28 100%)",
                boxShadow: "none",
                borderRadius: "10px",
                color: "white",
                "&:disabled": {
                  background: "gray",
                  color: "#ccc",
                },
              }}
            >
              {getStatusByIds(item?.certificate_status)?.id !== null &&
              getStatusByIds(item?.certificate_status)?.id !== 2
                ? "Issue"
                : "Issued"}
            </Button>
          </div>
        </div>
      )}
      <div className={styles.stepsCon}>
        {steps.map((steps, index) => {
          let ui;
          if (index + 1 === page) {
            ui = "active";
          } else if (index + 1 < page) {
            ui = "past";
          } else {
            ui = "future";
          }
          return (
            <div
              className={
                ui === "past"
                  ? `${styles.steps} ${styles.stepsa}`
                  : `${styles.steps} ${styles.stepsin}`
              }
            >
              <div className={styles.stepContainer}>
                <div
                  className={styles.circle}
                  style={{
                    border: `${
                      ui === "past"
                        ? "3px solid #008C76"
                        : ui === "future"
                        ? "3px solid #DDCA2380"
                        : "3px solid #ddca23"
                    }`,
                    background: `${
                      ui === "past"
                        ? "#008C76"
                        : ui === "future"
                        ? "#DDCA2380"
                        : ""
                    }`,
                    cursor: `${
                      ui === "past" || ui === "active" ? "pointer" : "auto"
                    }`,
                  }}
                  onClick={() => handleClick(ui, index + 1)}
                >
                  {ui === "active" && (
                    <div
                      className={styles.innerCircle}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                  )}
                  {ui === "past" && (
                    <img src={tick} className={styles.imgWid} />
                  )}
                </div>
              </div>
              <div className={styles.about}>
                <p className={styles.step}>{steps.step}</p>
                {steps.info.split("\n").map((line, index) => (
                  <p className={styles.stepName} key={index}>
                    {line}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HeaderNew;
